import { AnyAction, combineReducers, configureStore, Reducer } from '@reduxjs/toolkit'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web
import userReducer, { initialState as userInitialState } from './pages/Login/userSlice'
import squadsReducer, { initialState as squadsInitialState } from './components/Squad/squadListSlice'
import chatsReducer, { initialState as chatsInitialState } from './components/Chat/chatsSlice'
import websocketReducer, { initialState as websocketInitialState } from './components/WebsocketConnection/websocketSlice'


const persistConfig = {
  key: 'chat',
  storage,
  blacklist: ['squads', 'chats', 'websocket'],
}

const combinedReducer = combineReducers({
  user: userReducer,
  squads: squadsReducer,
  chats: chatsReducer,
  websocket: websocketReducer,
})

const initialState = {
  user: userInitialState,
  squads: squadsInitialState,
  chats: chatsInitialState,
  websocket: websocketInitialState,
}

export const rootReducer: Reducer = (state: RootState, action: AnyAction) => {
  if (action.type === 'user/logout') {
    return combinedReducer(initialState, action)
  }

  return combinedReducer(state, action)
}

const persistedReducer = persistReducer(persistConfig, rootReducer)
export const store = configureStore({ reducer: persistedReducer })
export const persistor = persistStore(store)

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof combinedReducer>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch
