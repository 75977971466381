import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { websocketSquadCreatedAction, websocketSquadListAction, websocketSquadUserAddedAction, websocketSquadUserRemovedAction, websocketSquadUsersUpdatedAction } from '../WebsocketConnection/WebsocketConnection'

export interface Squad {
  id: string
  title: string
  peers: string[]
}

interface State {
  squads: Squad[]
  currentSquadId?: string
}

export const initialState: State = {
  squads: []
}

export const squadsSlice = createSlice({
  name: 'squads',
  initialState,
  reducers: {
    setCurrentSquadId(state, { payload }: PayloadAction<string | undefined>) {
      state.currentSquadId = payload
    },
  },
  extraReducers: (builder => {
    builder
      .addCase(websocketSquadListAction, (state, { payload }) => {
        state.squads = payload.squadList
      })
      .addCase(websocketSquadCreatedAction, (state, { payload }) => {
        state.squads.push(payload.squad)
        state.currentSquadId = payload.squad.id
      })
      .addCase(websocketSquadUserAddedAction, (state, { payload }) => {
        state.squads.push(payload.squad)
      })
      .addCase(websocketSquadUserRemovedAction, (state, { payload }) => {
        state.squads = state.squads.filter(s => s.id !== payload.squadId)
        if (state.currentSquadId === payload.squadId) {
          state.currentSquadId = undefined
        }
      })
      .addCase(websocketSquadUsersUpdatedAction, (state, { payload }) => {
        for (var s of state.squads) {
          if (s.id === payload.squad.id) {
            s.peers = payload.squad.peers
            break
          }
        }
      })
  })
})

export const {
  setCurrentSquadId,
} = squadsSlice.actions

export default squadsSlice.reducer
