import { Button, Layout } from 'antd'
import { Content, Header } from 'antd/es/layout/layout'
import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from '../../store'
import { logout } from '../../pages/Login/userSlice'
import './DefaultLayout.scss'

interface Props {
	children: React.ReactNode
  contentClassName: string
}

function DefaultLayout({ children, contentClassName }: Props) {
  const { t } = useTranslation('Layout')
  const dispatch = useDispatch<AppDispatch>()
  const username = useSelector((state: RootState) => state.user.username)

  function onLogout() {
    dispatch(logout())
  }

  return (
    <Layout className='Layout'>
      <Header className='Header'>
        <div>
          <Trans
            i18nKey={'GREETING'}
            shouldUnescape={true}
            ns="Layout"
            values={{ username }}
            components={{ bold: <strong /> }}
          />
        </div>
        <Button danger type='primary' onClick={onLogout}>{t('Log out')}</Button>
      </Header>
      <Content className={ contentClassName }>
        {children}
      </Content>
    </Layout>
  )
}

export default DefaultLayout
