import React from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { ConfigProvider } from 'antd'
import Login from './pages/Login/LoginPage'
import HomePage from './pages/Home/HomePage'
import "./App.scss"
import SecureRoute from './components/security/SecureRoute'

function App() {
  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: "#00b96b",
          colorError: "#b9004d",
        },
      }}
    >
      <BrowserRouter>
        <Routes>
          <Route path="/login" element={<SecureRoute requireUser='anon'><Login /></SecureRoute>} />
          <Route path="/" element={<SecureRoute requireUser='authenticated'><HomePage /></SecureRoute>} />
        </Routes>
      </BrowserRouter>
    </ConfigProvider>
  )
}

export default App
