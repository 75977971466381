import React from 'react'
import { useSelector } from 'react-redux'
import { RootState } from '../../store'
import { Avatar, Button, Form, List, Select, Space } from 'antd'
import { useTranslation } from 'react-i18next'
import { MdDeleteForever } from 'react-icons/md'
import "./ChatPeers.scss"

interface Props {
  peersToModify?: string[]
  setPeersToModify: (peersToModify: string[] | undefined) => void
}

function ChatPeers({ peersToModify, setPeersToModify }: Props) {
  const { t } = useTranslation('Chats')
  const currentSquadId = useSelector((state: RootState) => state.squads.currentSquadId)
  const currentSquad = useSelector((state: RootState) => state.squads.squads.find(s => s.id === currentSquadId))
  const excludedPeersOfSquad = (currentSquad?.peers || []).filter(p => !peersToModify?.includes(p))
  const username = useSelector((state: RootState) => state.user.username)
  const [addPeerForm] = Form.useForm()

  function deletePeer(peerId: string) {
    setPeersToModify(peersToModify?.filter(p => p !== peerId))
  }

  function addPeer({ peer }: { peer: string }) {
    setPeersToModify([...peersToModify || [], peer])
    addPeerForm.setFieldsValue({
      peer: ''
    })
  }

  return (
    <>
      <List
        className="peers_list"
        itemLayout="horizontal"
        dataSource={peersToModify}
        renderItem={(peer) => (
          <List.Item>
            <Avatar src={`//robohash.org/${peer}.png`} />
            <div className='peers_list_username'>{peer}</div>
            <Button 
              onClick={() => deletePeer(peer)}
              type='text'
              disabled={peer === username}
            >
              <MdDeleteForever size="1.5em"/>
            </Button>
          </List.Item>
        )}
      />

      <Form
        form={addPeerForm}
        layout="horizontal"
        onFinish={addPeer}
      >
          <Space.Compact style={{ width: '100%' }}>
            <Form.Item
              name='peer' 
              label={false} 
              className='add_peer_input'
              rules={[{ required: true, message: 'Please select a peer' }]}
            >
              <Select showSearch placeholder={t('Select a peer')}>
                {excludedPeersOfSquad.map(p => (
                  <Select.Option key={p} value={p}>{p}</Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item>
              <Button type='primary' block htmlType='submit'>{t('Add')}</Button>
            </Form.Item>
          </Space.Compact>
      </Form>
    </>
  )
}

export default ChatPeers
