import { useEffect, useRef } from 'react'
import { WebsocketConnection } from './WebsocketConnection'
import { useDispatch } from 'react-redux'
import { AppDispatch } from '../../store'

export default function useWebsocketConnection(jwt: string) {
  const dispatch = useDispatch<AppDispatch>()
  const ws = useRef<WebsocketConnection | undefined>()
  
  useEffect(() => {
    ws.current = new WebsocketConnection(dispatch)
    ws.current.connect(jwt)
    const disconnectable = ws.current
    
    return () => {
      disconnectable.disconnect()
    }
  }, [])

  return ws
}
