import React, { useEffect } from 'react'
import DefaultLayout from '../../components/Layout/DefaultLayout'
import ChatsComponent from '../../components/Chat/Chats'
import "./HomePage.scss"
import SquadList from '../../components/Squad/SquadList'
import useWebsocketConnection from '../../components/WebsocketConnection/useWebsocketConnection'
import { useSelector } from 'react-redux'
import { RootState } from '../../store'
import { WebSocketConnectionContext } from '../../components/WebsocketConnection/WebSocketConnectionContext'

function HomePage() {
  const jwt = useSelector((state: RootState) => state.user.jwt)
  const username = useSelector((state: RootState) => state.user.username)
  const chatConnected = useSelector((state: RootState) => state.websocket.connected)
  const wsRef = useWebsocketConnection(jwt || "")

  useEffect(() => {
    if (chatConnected && wsRef.current && username) {
      wsRef.current.sendListSquads()
    }
  }, [chatConnected])

	return (
    <WebSocketConnectionContext.Provider value={wsRef.current}>
      <DefaultLayout contentClassName='ChatPage'>
        <div className='SquadListContainer'>
          <SquadList/>
        </div>
        <div className='ChatsContainer'>
          <ChatsComponent/>
        </div>
      </DefaultLayout>
    </WebSocketConnectionContext.Provider>
	)
}

export default HomePage
