import React, { useEffect, useState } from 'react'
import "./LoginPage.scss"
import { Button, Form, Input, Space } from 'antd'
import { useTranslation } from 'react-i18next'
import { BiUser } from 'react-icons/bi'
import { BsFillBuildingFill } from 'react-icons/bs'
import { attemptAuth } from './userSlice'
import { useDispatch } from 'react-redux'
import { AppDispatch } from '../../store'

function Login() {
  const { t } = useTranslation()
  const [form] = Form.useForm()
  const dispatch = useDispatch<AppDispatch>()

  const onFinish = (values: any) => {
    dispatch(attemptAuth({ username: values.username }))
  }

  const onFinishFailed = () => { }

  return (
    <main className='Login'>
      <Form
        className='Login-form'
        form={form}
        layout="vertical"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        <Form.Item
          name="username"
          label="Username"
          rules={[{ required: true }, { type: 'string', min: 1 }]}
        >
          <Input prefix=<BiUser/> />
        </Form.Item>
        <Form.Item>
          <Space>
            <Button type='primary' htmlType='submit'>{t('Connect')}</Button>
          </Space>
        </Form.Item>
      </Form>
      
    </main>
  )
}

export default Login
