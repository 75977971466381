import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { websocketChatListAction, websocketChatCreatedAction, websocketChatMessagesAction, websocketChatMessageReceivedAction, websocketChatUsersUpdatedAction, websocketChatUserAddedAction, websocketChatUserRemovedAction, websocketSquadUserRemovedAction } from '../WebsocketConnection/WebsocketConnection'
import { Peer } from '../Peer'
import { setCurrentSquadId } from '../Squad/squadListSlice'

export interface ChatMessage {
  id: string
  peerId: string
  createdAt: string
  content: string
}

export interface Chat {
  id: string
  title: string
  everybody: boolean
  peerToPeer: boolean
  peers: string[]
  squadId: string
  messages?: ChatMessage[]
}

interface State {
  me?: Peer
  chats: { [squadId: string]: Chat[] }
  currentChatId?: string
}

export const initialState: State = {
  chats: { }
}

export const chatSlice = createSlice({
  name: 'chat',
  initialState,
  reducers: {
    setCurrentChatId(state, { payload }: PayloadAction<string>) {
      state.currentChatId = payload
    },
  },
  extraReducers: (builder => {
    builder
      .addCase(websocketChatListAction, (state, { payload }) => {
        state.chats[payload.squadId] = payload.chatList
      })
      .addCase(websocketChatCreatedAction, (state, { payload }) => {
        if (!state.chats[payload.chat.squadId]) {
          state.chats[payload.chat.squadId] = []
        }
        state.chats[payload.chat.squadId].push(payload.chat)
      })
      .addCase(websocketChatMessagesAction, (state, { payload }) => {
        for (var c of state.chats[payload.squadId] || []) {
          if (c.id === payload.chatId) {
            c.messages = payload.messages
            break
          }
        }
      })
      .addCase(websocketChatMessageReceivedAction, (state, { payload }) => {
        for (var c of state.chats[payload.squadId] || []) {
          if (c.id === payload.chatId) {
            c.messages?.push(payload.message)
            break
          }
        }
      })
      .addCase(websocketChatUserAddedAction, (state, { payload }) => {
        if (!state.chats[payload.squadId]) {
          state.chats[payload.squadId] = []
        }
        state.chats[payload.squadId].push(payload.chat)
      })
      .addCase(websocketChatUserRemovedAction, (state, { payload }) => {
        if (state.chats[payload.squadId]) {
          state.chats[payload.squadId] = state.chats[payload.squadId].filter(chat => chat.id !== payload.chatId)
        }
        if (state.currentChatId === payload.chatId) {
          state.currentChatId = undefined
        }
      })
      .addCase(websocketChatUsersUpdatedAction, (state, { payload }) => {
        for (var c of state.chats[payload.squadId] || []) {
          if (c.id === payload.chat.id) {
            c.peers = payload.chat.peers
            break
          }
        }
      })
      .addCase(setCurrentSquadId, (state, { payload }) => {
        state.currentChatId = undefined
      })
      .addCase(websocketSquadUserRemovedAction, (state, { payload }) => {
        if (state.chats[payload.squadId]) {
          for (const chat of state.chats[payload.squadId]) {
            if (chat.id === state.currentChatId) {
              if (chat.squadId === payload.squadId) {
                state.currentChatId = undefined
              }
              break
            }
          }
        }
      })
  })
})

export const {
  setCurrentChatId,
} = chatSlice.actions

export default chatSlice.reducer
