import React, { useContext } from 'react'
import { Button, Form, Input } from 'antd'
import { useTranslation } from 'react-i18next'
import { WebSocketConnectionContext } from '../WebsocketConnection/WebSocketConnectionContext'
import { useSelector } from 'react-redux'
import { RootState } from '../../store'

function SquadCreateForm() {
  const { t } = useTranslation('SquadList')
  const [form] = Form.useForm()
  const ws = useContext(WebSocketConnectionContext)
  const username = useSelector((state: RootState) => state.user.username)

  function onSubmit({ title }: { title: string }) {
    ws?.sendCreateSquad(title, username ? [username] : [])
    form.setFieldsValue({ title: '' })
  }

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={onSubmit}
    >
      <Form.Item 
        name='title' 
        label={false} 
        rules={[{ required: true, message: 'Please input a title' }]}
      >
        <Input placeholder={t('New squad title')} />
      </Form.Item>
      <Form.Item>
        <Button type='primary' block htmlType='submit'>{t('Create new squad')}</Button>
      </Form.Item>
    </Form>
  )
}

export default SquadCreateForm
