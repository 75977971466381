import React, { useContext } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from '../../store'
import ChatList from './ChatList'
import Chat from './Chat'
import { Alert, Spin } from 'antd'
import { WebSocketConnectionContext } from '../WebsocketConnection/WebSocketConnectionContext'
import { useTranslation } from 'react-i18next'
import "./Chats.scss"

function ChatsComponent() {
  const { t } = useTranslation('Chats')
  const currentSquadId = useSelector((state: RootState) => state.squads.currentSquadId)
  const currentChatId = useSelector((state: RootState) => state.chats.currentChatId)
  const ws = useContext(WebSocketConnectionContext)

  return (
    ws
      ? (
        <div className='Chats'>
          <div className='chat_list_container'>
            <ChatList />
          </div>
          <div className='chat_container'>
            {currentChatId && currentSquadId
              ? <Chat currentSquadId={currentSquadId} currentChatId={currentChatId} />
              : <div className='p-1em'><Alert type='warning' message={t('Please select a chat')}/></div>
            }
          </div>
        </div>
      )
      : <Spin/>
  )
}

export default ChatsComponent
