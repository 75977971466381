import 'dayjs/locale/en'
import 'dayjs/locale/fr'
import 'dayjs/locale/de'
import 'dayjs/locale/it'
import 'dayjs/locale/uk'
import i18n from "i18next"
import { initReactI18next } from "react-i18next"
import chatEN from './trans/Chat/en.json'
import chatFR from './trans/Chat/fr.json'
import chatListEN from './trans/ChatList/en.json'
import chatListFR from './trans/ChatList/fr.json'
import layoutEN from './trans/Layout/en.json'
import layoutFR from './trans/Layout/fr.json'
import SquadListEN from './trans/SquadList/en.json'
import SquadListFR from './trans/SquadList/fr.json'

export type Locales = "fr" | "en"

const resources = {
	en: {
    Chat: chatEN,
    ChatList: chatListEN,
    Layout: layoutEN,
    SquadList: SquadListEN,
	},
	fr: {
    Chat: chatFR,
    ChatList: chatListFR,
    Layout: layoutFR,
    SquadList: SquadListFR,
	},
}

i18n
	.use(initReactI18next)
	.init({
		lng: localStorage.getItem("settingsLng") || process.env.REACT_APP_DEFAULT_LOCALE,
		fallbackLng: 'en',
		resources,
		nsSeparator: false
	})


i18n.on("languageChanged", lng => {
	localStorage.setItem("settingsLng", lng)
})

export default i18n
