import React, { useContext, useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from '../../store'
import TextArea from 'antd/es/input/TextArea'
import { Avatar, Button, Modal, Spin, Tooltip } from 'antd'
import { Trans, useTranslation } from 'react-i18next'
import { WebSocketConnectionContext } from '../WebsocketConnection/WebSocketConnectionContext'
import classNames from 'classnames'
import dayjs from 'dayjs'
import { FiUsers } from 'react-icons/fi'
import { IoMdSend } from 'react-icons/io'
import ChatPeers from './ChatPeers'
import { debounce } from 'lodash'
import ReactQuill from 'react-quill'
import sanitizeHtml from 'sanitize-html'
import 'react-quill/dist/quill.snow.css'
import "./Chat.scss"


interface Props {
  currentSquadId: string
  currentChatId: string
}

function Chat({ currentSquadId, currentChatId }: Props) {
  const { t } = useTranslation("chat")
  const currentChat = useSelector((state: RootState) => 
    (state.chats.chats[currentSquadId] || []).find(c => c.id === currentChatId)
  )
  const username = useSelector((state: RootState) => state.user.username)
  const [value, setValue] = useState("")
  const ws = useContext(WebSocketConnectionContext)
  const [peersToModify, setPeersToModify] = useState<string[] | undefined>()
  const messagesContainerRef = useRef<HTMLDivElement>(null)
  const [scrolledToBottom, setScrolledToBottom] = useState(true)

  useEffect(() => {
    ws?.sendFetchChatMessages(currentSquadId, currentChatId)
  }, [currentChatId])

  useEffect(() => {
    if (scrolledToBottom) {
      smoothScrollToBottom()
    }
  }, [currentChat?.messages])

  const handleScroll = debounce((e: any) => {
    const { scrollTop, clientHeight, scrollHeight } = e.target
    setScrolledToBottom(scrollTop + clientHeight === scrollHeight)
  }, 200)

  function smoothScrollToBottom() {
    messagesContainerRef.current?.scrollTo({
      top: messagesContainerRef.current.scrollHeight,
      behavior: 'smooth',
    })
  }

  function sendMessage() {
    ws?.sendChatMessage(currentSquadId, currentChatId, value)
    setValue("")
  }

  function updatePeers() {
    if (peersToModify) {
      ws?.sendUpdateChatPeers(currentChatId, peersToModify)
    }
    setPeersToModify(undefined)
  }

  const quillToolbar = [
    ['bold', 'italic', 'underline', 'strike'],
    [{ list: 'ordered' }, { list: 'bullet' }],
  ]

  const quillFormat = [
    'bold',
    'color',
    'font',
    'code',
    'italic',
    'link',
    'strike',
    'script',
    'underline',
    'blockquote',
    'header',
    'indent',
    'list',
    'align',
    'direction',
    'code-block',
    'formula',
  ]

  return (
    <div className='Chat'>
      <div className='chat_header'>
        <div className='chat_header_title_container'>
          <Avatar className='chat_header_avatar' src={`//robohash.org/${currentChat?.id}.png`} />
          <span className='chat_header_title'>
            {currentChat?.everybody 
              ? t('Everybody in this squad')
              : (
                currentChat?.peerToPeer 
                  ? currentChat.peers.filter(p => p !== username)[0]
                  : currentChat?.title
              )
            }
            
          </span>
        </div>
        <div>
          {currentChat?.everybody
            ? t('All users of squad')
            : (
              !currentChat?.peerToPeer && (
                <>
                  <Trans
                    i18nKey={'COUNT_USERS'}
                    shouldUnescape={true}
                    ns="Chat"
                    values={{ count: currentChat?.peers.length }}
                  />
                  <Button onClick={() => setPeersToModify(currentChat?.peers)} type='link'><FiUsers/></Button>
                </>
              )
            )
          }
          
        </div>
      </div>
      <div className='chat_messages' ref={messagesContainerRef} onScroll={handleScroll}>
        {Array.isArray(currentChat?.messages) 
          ? (
            currentChat?.messages?.length === 0
              ? (<div>{t('Send first message to this chat')}</div>)
              : (
                currentChat?.messages?.map((message) => {
                  return (
                    <div 
                      className={classNames({
                        chat_message: true, 
                        'chat_message--self': message.peerId === username
                      })}
                      key={message.id}
                    >
                      <div className='chat_message_header'>
                        <div className='chat_message_author'>{message.peerId}</div>
                        <div className='chat_message_date'>
                          <Tooltip title={dayjs(message.createdAt).format('DD/MM/YYYY')}>
                            {dayjs(message.createdAt).format('HH:mm')}
                          </Tooltip>
                        </div>
                      </div>
                      <div
                        className='chat_message_content'
                        dangerouslySetInnerHTML={{
                          __html: sanitizeHtml(message.content, {
                            allowedTags: ['b', 'p', 'ol', 'il', 'strong', 'em', 's', 'u'],
                          }),
                        }}
                      />
                    </div>
                  )
                })
              )
          )
          : <Spin/>
        }
      </div>
      <div className='chat_actions'>
        <ReactQuill
          className="text-area-editor"
          theme="snow"
          placeholder={t('Write a message...')}
          value={value}
          onChange={(content) => setValue(content)}
          modules={{ toolbar: quillToolbar }}
          formats={quillFormat}
        />
        <Button type="link" size='small' onClick={sendMessage} className='pr-0' disabled={value.length === 0}>
          <IoMdSend size="2em"/>
        </Button>
      </div>

      <Modal
        title={t('Peers of chat')}
        open={peersToModify !== undefined}
        onOk={updatePeers}
        onCancel={() => setPeersToModify(undefined)}
      >
        <ChatPeers peersToModify={peersToModify} setPeersToModify={setPeersToModify}/>
      </Modal>
    </div>
  )
}

export default Chat