import { createAction } from "@reduxjs/toolkit"
import { AppDispatch } from "../../store"
import { setConnected } from "./websocketSlice"
import { Squad } from "../Squad/squadListSlice"
import { Chat, ChatMessage } from "../Chat/chatsSlice"

interface Message {
  type: MessageType
}

interface SquadListMessage extends Message {
  squadList: Squad[]
}

interface SquadCreatedMessage extends Message {
  squad: Squad
}

interface SquadUserAddedMessage extends Message {
  squad: Squad
}

interface SquadUserRemovedMessage extends Message {
  squadId: string
}

interface SquadUsersUpdatedMessage extends Message {
  squad: Squad
}

interface ChatListMessage extends Message {
  squadId: string
  chatList: Chat[]
}

interface ChatCreatedMessage extends Message {
  chat: Chat
}

interface ChatMessagesMessage extends Message {
  squadId: string
  chatId: string
  messages: ChatMessage[]
}

interface ChatMessageReceivedMessage extends Message {
  squadId: string
  chatId: string
  message: ChatMessage
}

interface ChatUserAddedMessage extends Message {
  squadId: string
  chat: Chat
}

interface ChatUserRemovedMessage extends Message {
  squadId: string
  chatId: string
}

interface ChatUsersUpdatedMessage extends Message {
  squadId: string
  chat: Chat
}


enum MessageType {
  SQUAD_LIST = "SQUAD_LIST",
  SQUAD_CREATED = "SQUAD_CREATED",
  SQUAD_USER_ADDED = "SQUAD_USER_ADDED",
  SQUAD_USER_REMOVED = "SQUAD_USER_REMOVED",
  SQUAD_USERS_UPDATED = "SQUAD_USERS_UPDATED",
  CHAT_LIST = "CHAT_LIST",
  CHAT_CREATED = "CHAT_CREATED",
  CHAT_MESSAGES = "CHAT_MESSAGES",
  CHAT_MESSAGE_RECEIVED = "CHAT_MESSAGE_RECEIVED",
  CHAT_USER_ADDED = "CHAT_USER_ADDED",
  CHAT_USER_REMOVED = "CHAT_USER_REMOVED",
  CHAT_USERS_UPDATED = "CHAT_USERS_UPDATED",
}

export const websocketSquadListAction = createAction<SquadListMessage>('websocket-connection/squad-list')
export const websocketSquadCreatedAction = createAction<SquadCreatedMessage>('websocket-connection/squad-created')
export const websocketSquadUserAddedAction = createAction<SquadUserAddedMessage>('websocket-connection/squad-user-added')
export const websocketSquadUserRemovedAction = createAction<SquadUserRemovedMessage>('websocket-connection/squad-user-removed')
export const websocketSquadUsersUpdatedAction = createAction<SquadUsersUpdatedMessage>('websocket-connection/squad-users-updated')
export const websocketChatListAction = createAction<ChatListMessage>('websocket-connection/chat-list')
export const websocketChatCreatedAction = createAction<ChatCreatedMessage>('websocket-connection/chat-created')
export const websocketChatMessagesAction = createAction<ChatMessagesMessage>('websocket-connection/chat-messages')
export const websocketChatMessageReceivedAction = createAction<ChatMessageReceivedMessage>('websocket-connection/chat-message-received')
export const websocketChatUserAddedAction = createAction<ChatUserAddedMessage>('websocket-connection/chat-user-added')
export const websocketChatUserRemovedAction = createAction<ChatUserRemovedMessage>('websocket-connection/chat-user-removed')
export const websocketChatUsersUpdatedAction = createAction<ChatUsersUpdatedMessage>('websocket-connection/chat-users-updated')

export class WebsocketConnection {
  private _dispatch: AppDispatch
  private _ws?: WebSocket

  constructor(dispatch: AppDispatch) {
    console.debug('WebsocketConnection::constructor')
    this._dispatch = dispatch
  }

  public connect(jwt: string) {
    console.debug('WebsocketConnection::connect')
    this.disconnect()
    this._ws = new WebSocket(process.env.REACT_APP_CHAT_BASE_WS_URL + '/websocket/chat?token=' + jwt)

    this._ws.onmessage = (msg) => {
      try {
        const data = JSON.parse(msg.data)

        switch (data.type) {
          case MessageType.SQUAD_LIST:
            this._dispatch(websocketSquadListAction(data))
            break

          case MessageType.SQUAD_CREATED:
            this._dispatch(websocketSquadCreatedAction(data))
            break

          case MessageType.SQUAD_USER_ADDED:
            this._dispatch(websocketSquadUserAddedAction(data))
            break

          case MessageType.SQUAD_USER_REMOVED:
            this._dispatch(websocketSquadUserRemovedAction(data))
            break

          case MessageType.SQUAD_USERS_UPDATED:
            this._dispatch(websocketSquadUsersUpdatedAction(data))
            break

          case MessageType.CHAT_LIST:
            this._dispatch(websocketChatListAction(data))
            break

          case MessageType.CHAT_CREATED:
            this._dispatch(websocketChatCreatedAction(data))
            break

          case MessageType.CHAT_MESSAGES:
            this._dispatch(websocketChatMessagesAction(data))
            break

          case MessageType.CHAT_MESSAGE_RECEIVED:
            this._dispatch(websocketChatMessageReceivedAction(data))
            break

          case MessageType.CHAT_USER_ADDED:
            this._dispatch(websocketChatUserAddedAction(data))
            break

          case MessageType.CHAT_USER_REMOVED:
            this._dispatch(websocketChatUserRemovedAction(data))
            break

          case MessageType.CHAT_USERS_UPDATED:
            this._dispatch(websocketChatUsersUpdatedAction(data))
            break

          default:
            throw new Error("Unknown message type: " + msg.data)
        }
      } catch(e) {
        console.error(e)
      }
    }

    this._ws.onopen = (e) => {
      console.debug('WebsocketConnection::onopen', e)
      this._dispatch(setConnected(true))
    }

    this._ws.onclose = (e) => {
      console.debug('WebsocketConnection::onclose', e)
      this._dispatch(setConnected(false))
    }
  }

  public disconnect() {
    console.debug('WebsocketConnection::disconnect', this._ws)
    if (this._ws) {
      this._ws.onopen = null
      this._ws.onclose = null
      this._ws.onerror = null
      this._ws.onmessage = null
      this._ws.close()
      this._ws = undefined
    }
  }

  public sendListSquads() {
    const data = JSON.stringify({
      type: "LIST_SQUADS",
    })

    this.send(data)
  }
  
  public sendCreateSquad(title: string, peers: string[]) {
    const data = JSON.stringify({
      type: "CREATE_SQUAD",
      payload: {
        title,
        peers: JSON.stringify(peers),
      }
    })

    this.send(data)
  }

  public sendUpdateSquadPeers(squadId: string, peers: string[]) {
    const data = JSON.stringify({
      type: "UPDATE_SQUAD_PEERS",
      payload: {
        squadId,
        peers: JSON.stringify(peers),
      },
    })

    this.send(data)
  }

  public sendCreateChat(title: string, squadId?: string) {
    const data = JSON.stringify({
      type: "CREATE_CHAT",
      payload: { title, squadId }
    })

    this.send(data)
  }

  public sendListChats(squadId?: string) {
    const data = JSON.stringify({
      type: "LIST_CHATS",
      payload: { squadId }
    })

    this.send(data)
  }

  public sendFetchChatMessages(squadId: string, chatId: string) {
    const data = JSON.stringify({
      type: "FETCH_CHAT_MESSAGES",
      payload: { squadId, chatId },
    })

    this.send(data)
  }

  public sendChatMessage(squadId: string, chatId: string, content: string) {
    const data = JSON.stringify({
      type: "CREATE_CHAT_MESSAGE",
      payload: { squadId, chatId, content },
    })

    this.send(data)
  }

  public sendUpdateChatPeers(chatId: string, peers: string[]) {
    const data = JSON.stringify({
      type: "UPDATE_CHAT_PEERS",
      payload: {
        chatId,
        peers: JSON.stringify(peers),
      },
    })

    this.send(data)
  }

  private send(data: string) {
    this._ws?.send(data)
  }
}
