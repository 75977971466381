import React from 'react'
import { useSelector } from 'react-redux'
import { Navigate } from 'react-router'
import { RootState } from '../../store'

interface Props {
  children: any
  requireUser: "anon" | "authenticated"
}

function SecureRoute({ children, requireUser }: Props) {
  const jwt = useSelector((state: RootState) => state.user.jwt)

  if (requireUser === "authenticated" && !jwt) {
    return <Navigate to="/login" replace />
  }

  if (requireUser === "anon" && jwt) {
    return <Navigate to="/" replace />
  }

  return children
}

export default SecureRoute
