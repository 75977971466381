import React, { useContext, useState } from 'react'
import { Avatar, Button, List, Modal, Tooltip } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from '../../store'
import { Squad, setCurrentSquadId } from './squadListSlice'
import { useTranslation } from 'react-i18next'
import SquadCreateForm from './SquadCreateForm'
import classnames from 'classnames'
import "./SquadList.scss"
import { FiUsers } from 'react-icons/fi'
import SquadPeers from './SquadPeers'
import { WebSocketConnectionContext } from '../WebsocketConnection/WebSocketConnectionContext'
import { BsFillInfoCircleFill } from 'react-icons/bs'

function SquadList() {
	const { t } = useTranslation('SquadList')
	const dispatch = useDispatch<AppDispatch>()
	const squads = useSelector((state: RootState) => state.squads.squads)
  const currentSquadId = useSelector((state: RootState) => state.squads.currentSquadId)
  const [squadToModify, setSquadToModify] = useState<Squad | undefined>()
  const [peersToModify, setPeersToModify] = useState<string[] | undefined>()
  const ws = useContext(WebSocketConnectionContext)

	function toggleSquad(id: string) {
    dispatch(setCurrentSquadId(
      currentSquadId === id 
        ? undefined // Unselect
        : id        // Select
    ))
	}

  function modifyPeers(squad: Squad) {
    setSquadToModify(squad)
    setPeersToModify(squad.peers)
  }

  function cancelModifyPeers() {
    setSquadToModify(undefined)
    setPeersToModify(undefined)
  }

  function updatePeers() {
    if (squadToModify && peersToModify) {
      ws?.sendUpdateSquadPeers(squadToModify.id, peersToModify)
    }
    
    setSquadToModify(undefined)
    setPeersToModify(undefined)
  }

	return (
		<div className='SquadList'>
			<h3 className='squad_header'>
        <span className='mr-04em'>{t('Your squads')}</span>
        <Tooltip title={
          <>
            Any group of people: 
            <ul>
              <li>video call participants</li>
              <li>patient, doctor, operator</li>
              <li>drive participants</li>
              <li>etc</li>
            </ul>
          </>
          }
        ><BsFillInfoCircleFill/></Tooltip> 
      </h3>
			<SquadCreateForm/>
      {squads.map(
        squad => {
          return (
            <div 
              key={squad.id}
              className={classnames({
                'squad_item': true,
                'squad_item--current': squad.id === currentSquadId,
              })}
            >
              <div className='squad_item_title' onClick={() => toggleSquad(squad.id)}>
                <Avatar src={`//robohash.org/${squad.id}.png`} />
                <div className='ml-05em'>{squad.title}</div>
              </div>
              <div className='squad_item_actions'>
                <Button onClick={() => modifyPeers(squad)} type='text' size='small' className='squad_item_peers'>
                  <div className='mr-04em'>{squad.peers.length}</div>
                  <FiUsers/>
                </Button>
              </div>
            </div>
          )
        }
      )}

      <Modal
        title={t('Peers of squad')}
        open={peersToModify !== undefined}
        onOk={updatePeers}
        onCancel={cancelModifyPeers}
      >
        {peersToModify !== undefined && (
          <SquadPeers peersToModify={peersToModify} setPeersToModify={setPeersToModify}/>
        )}
      </Modal>
		</div>
	)
}

export default SquadList
