import React, { useContext, useEffect } from 'react'
import { Alert, Avatar, Collapse, Tag } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from '../../store'
import { Chat, setCurrentChatId } from './chatsSlice'
import { Trans, useTranslation } from 'react-i18next'
import "./ChatList.scss"
import ChatCreateForm from './ChatCreateForm'
import { WebSocketConnectionContext } from '../WebsocketConnection/WebSocketConnectionContext'
import classnames from 'classnames'


function ChatList() {
	const chats = useSelector((state: RootState) => state.chats.chats)
  const username = useSelector((state: RootState) => state.user.username)
  const currentSquadId = useSelector((state: RootState) => state.squads.currentSquadId)
  const currentChatId = useSelector((state: RootState) => state.chats.currentChatId)
  const squad = useSelector((state: RootState) => state.squads.squads.find(squad => squad.id === currentSquadId))
	const dispatch = useDispatch<AppDispatch>()
  const ws = useContext(WebSocketConnectionContext)
  const { t } = useTranslation('ChatList')

	function selectChat(chatId: string) {
		dispatch(setCurrentChatId(chatId))
	}

  useEffect(() => {
    ws?.sendListChats(squad?.id)
  }, [squad])

  let everybodyChat: Chat | undefined
  let p2pChats = []
  let customChats = []

  for (const c of chats[squad?.id || ''] || []) {
    if (c.everybody) {
      everybodyChat = c
    }
    else if (c.peerToPeer) {
      p2pChats.push(c)
    }
    else {
      customChats.push(c)
    }
  }
	
	return (
    <div className='ChatListComponent'>
      {squad
        ? (
          <>
            <h3>
              <Trans
                i18nKey={'YOUR_CHATS_FOR_SQUAD'}
                shouldUnescape={true}
                ns="ChatList"
                values={{ squad: squad.title }}
                components={{ bold: <strong /> }}
              />
            </h3>

            <ChatCreateForm squadId={squad?.id}/>

            {everybodyChat && (
              <div
                onClick={() => everybodyChat && selectChat(everybodyChat.id)}
                className={classnames({
                  'chat_item': true,
                  'chat_item--everybody': true,
                  'chat_item--current': everybodyChat.id === currentChatId,
                })}
              >
                <Avatar src={`//robohash.org/${everybodyChat.id}.png`} />
                <div className='ml-05em'>{t('Everybody in this squad')}</div>
              </div>
            )}
          
            {customChats.length > 0 && (
              <Collapse
                className='chat_group'
                collapsible="header"
                defaultActiveKey={['1']}
                ghost
                items={[
                  {
                    key: '1',
                    label: <>{t('Custom chats')} <Tag color="volcano">{customChats.length}</Tag></>,
                    children: customChats.map(
                      chat => (
                        <div
                          key={chat.id}
                          onClick={() => selectChat(chat.id)} 
                          className={classnames({
                            'chat_item': true,
                            'chat_item--current': chat.id === currentChatId,
                          })}
                        >
                          <Avatar src={`//robohash.org/${chat.id}.png`} />
                          <div className='ml-05em'>{chat.title}</div>
                        </div>
                      )
                    ),
                  },
                ]}
              />
            )}
            
            {p2pChats.length > 0 && (
              <Collapse
                className='chat_group'
                defaultActiveKey={['1']}
                collapsible="header"
                ghost
                items={[
                  {
                    key: '1',
                    label: <>{t('Direct messages')} <Tag color="volcano">{p2pChats.length}</Tag></>,
                    children: p2pChats.map(
                      chat => (
                        <div
                          key={chat.id}
                          onClick={() => selectChat(chat.id)} 
                          className={classnames({
                            'chat_item': true,
                            'chat_item--current': chat.id === currentChatId,
                            'chat_item--p2p': chat.id === currentChatId,
                          })}
                        >
                          <Avatar src={`//robohash.org/${chat.id}.png`} />
                          <div className='ml-05em'>{chat.peers.filter(p => p !== username)[0]}</div>
                        </div>
                      )
                    ),
                  },
                ]} 
              />
            )}
          </>
        ) : (
          <Alert type='warning' message={t('Please select a squad')} />
        )
      }
    </div>
	)
}

export default ChatList
