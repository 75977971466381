import React, { useContext } from 'react'
import { Button, Form, Input } from 'antd'
import { useTranslation } from 'react-i18next'
import { WebSocketConnectionContext } from '../WebsocketConnection/WebSocketConnectionContext'

interface Props {
  squadId?: string
}

function ChatCreateForm({ squadId }: Props) {
  const { t } = useTranslation('ChatList')
  const [form] = Form.useForm()
  const ws = useContext(WebSocketConnectionContext)

  function onSubmit({ title }: { title: string }) {
    ws?.sendCreateChat(title, squadId)
    form.setFieldsValue({
      title: ''
    })
  }

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={onSubmit}
    >
      <Form.Item 
        name='title' 
        label={false}
        rules={[{ required: true, message: 'Please input a title' }]}
      >
        <Input placeholder={t('New chat title')} />
      </Form.Item>
      <Form.Item>
        <Button type='primary' block htmlType='submit'>{t('Create new chat')}</Button>
      </Form.Item>
    </Form>
  )
}

export default ChatCreateForm
