import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

type FetchStatus = "idle" | "loading" | "success" | "error"

interface State {
  username?        : string
  jwt?             : string
  attemptAuthStatus: FetchStatus
}


export const initialState: State = {
  attemptAuthStatus: "idle",
}


/**
 * Auth user with username.
 */
export const attemptAuth = createAsyncThunk(
  'user/attemptAuth',
  async (payload: { username: string }) => {
    
    const response = await fetch(process.env.REACT_APP_CHAT_BASE_REST_URL + '/auth/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(payload),
    })

    const body = await response.json()
    return {
      username: payload.username,
      jwt: body.token,
    }
  }
)


export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    idleAttemptAuthStatus: (state) => {
      state.attemptAuthStatus = "idle"
    },
    logout: () => {
      // Handled in store.ts in rootReducer
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(attemptAuth.pending, (state) => {
        state.attemptAuthStatus = "loading"
      })
      .addCase(attemptAuth.fulfilled, (state, { payload }) => {
        state.attemptAuthStatus = "success"
        state.username = payload.username
        state.jwt = payload.jwt
      })
      .addCase(attemptAuth.rejected, (state) => {
        state.attemptAuthStatus = "error"
      })
  }
})

export const {
  idleAttemptAuthStatus,
  logout,
} = userSlice.actions

export default userSlice.reducer
